import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  {
    name: 'notFound',
    path: '/:path(.*)+',
    redirect: {
      name: 'kp',
    },
  },
  {
    name: 'kp',
    path: '/kp',
    component: () => import('./view/kp/kpsq.vue'),
    meta: {
      title: '上海迪士尼度假区',
    }
  },
  {
    name: 'loading',
    path: '/loading',
    component: () => import('./view/kp/loading.vue'),
    meta: {
      title: '上海迪士尼度假区',
    },
  },
  {
    name: 'fpkj',
    path: '/fpkj',
    component: () => import('./view/kp/fpkj.vue'),
    meta: {
      title: '上海迪士尼度假区',
    },
  },
  {
    name: 'kpconfirm',
    path: '/kpconfirm',
    component: () => import('./view/kp/kpconfirm.vue'),
    meta: {
      title: '上海迪士尼度假区',
    },
  },
  {
    name: 'dealing',
    path: '/dealing',
    component: () => import('./view/kp/dealing.vue'),
    meta: {
      title: '上海迪士尼度假区',
    },
  },
  {
    name: 'searchinvoice',
    path: '/searchinvoice',
    component: () => import('./view/kp/searchinvoice.vue'),
    meta: {
      title: '上海迪士尼度假区',
    },
  },
  {
    name: 'kpsuccess',
    path: '/kpsuccess',
    component: () => import('./view/kp/kpsuccess.vue'),
    meta: {
      title: '上海迪士尼度假区',
    },
  },
  {
    name: 'showFapiao',
    path: '/showFapiao',
    component: () => import('./view/kp/showFapiao.vue'),
    meta: {
      title: '上海迪士尼度假区',
    },
  },
  {
    name: 'systemerr',
    path: '/systemerr',
    component: () => import('./view/kp/systemerr.vue'),
    meta: {
      title: '上海迪士尼度假区',
    },
  },
  {
    name: 'suzhouclose',
    path: '/suzhouclose',
    component: () => import('./view/kp/suzhouclose.vue'),
    meta: {
      title: '上海迪士尼度假区',
    },
  },
  {
    name: 'kpfailed',
    path: '/kpfailed',
    component: () => import('./view/kp/kpfailed.vue'),
    meta: {
      title: '上海迪士尼度假区',
    },
  },
  {
    name: 'navigationBar',
    path: '/navigationBar',
    component: () => import('./view/kp/navigationBar.vue'),
    meta: {
      title: '上海迪士尼度假区',
    },
  },
];

const router = createRouter({
  base: '/disney/mobileui',
  routes,
  history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (localStorage.getItem('lang') == 'zh') {
    if (title) {
      document.title = title;
    }
  }
  else {
      document.title = "Shanghai Disney Resort";
  }
  next();
});

export { router };
