import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
import _imports_0 from '../../../public/xxk.png';
const _hoisted_1 = {
  style: {
    "padding-top": "160px"
  }
};
const _hoisted_2 = {
  class: "close3 systemfont fullstyle"
};
const _hoisted_3 = {
  class: "close4 systemfont fullstyle"
};
const _hoisted_4 = {
  class: "errtry systemfont fullstyle"
};
const _hoisted_5 = {
  class: "errtry1 systemfont fullstyle"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return _openBlock(), _createElementBlock("div", {
    style: _normalizeStyle({
      width: '100%',
      height: $data.tempheight
    })
  }, [_createElementVNode("div", _hoisted_1, [_cache[0] || (_cache[0] = _createElementVNode("div", {
    class: "fullstyle"
  }, [_createElementVNode("img", {
    src: _imports_0,
    style: {
      "height": "172px"
    }
  })], -1)), _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('ErrScenario.Closetitle')), 1), _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('ErrScenario.Closetitle1')), 1), _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t('ErrScenario.Closevalue')), 1), _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('ErrScenario.Closevalue1')), 1)])], 4);
}