const ZH_CN = {
	ManPage: {
		Pagetitle: '上海迪士尼度假区',
		PageContent: '申请电子发票',
		Inputbar: '请输入开票提取码',
		Inputbari: '开票提取码',
		Remarks: '小票上找不到开票提取码？请点击这里',
		Remarkfjy: '小票上找不到交易单号？请',
		Remarkfzd: '小票上找不到账单号？请',
		Remarkft: '小票上找不到开票提取码？请',
		Remarkaf: '点击这里',
		Button: '查询',
		poptitle: '开票说明及常见问题',
		popcontent1: '为保障每位游客的健康和安全，根据相关法律和政府规定，每一位到访上海迪士尼乐园的游客 (包括儿童)，在到访之前，必须进行游客信息登记（点击进行登记：https://www.shanghaidisneyresort.com/reopen-digital-form)。收集的信息仅用于防疫工作。详情请查看乐园须知和入园登记须知。',
		popcontent2: '在您入园之前，我们的演职人员会测量您的体温并请您出示“健康信息码”。请携带您的身份证明原件（内地游客，请携带中华人民共和国居民身份证；香港、澳门地区游客，请携带《港澳居民来往内地通行证》或《中华人民共和国旅行证》；台湾地区游客，请携带《台湾居民来往大陆通行证》或《中华人民共和国旅行证》；其他国家及地区游客，请携带有效护照），上海迪士尼度假区将在您入园时收集您身份证件上所载的身份证件号。',
		popcontent3: '自5月11日起，上海迪士尼乐园内的大部分景点、游乐项目、部分娱乐演出以及商店和餐厅在控制客流量的基础上恢复运营。为了保证安全距离，备受喜爱的迪士尼朋友们已以全新的方式与游客们见面。他们会出现在上海迪士尼乐园的各个主题园区，欢迎大家回到这个神奇的地方。但在乐园恢复运营初期，与迪士尼朋友的亲密互动以及近距离合影仍暂时取消。',
		errtitle: '失败',
		errcont: '小票可开金额为零，无法开票',
		closeyes: '确定',
		closecancel: '取消',
		barerrormsg: '请输入正确的开票提取码',
		location: '开票提取码位置',
		jycode: '交易单号位置',
		zdcode: '账单号位置',
		findjycode: '查看交易单号位置',
		findzdcode: '查看账单号位置',
	},
	ErrMsg: {
		incorrect: '开票提取码输入错误，请输入正确的开票提取码',
		available: '未找到该订单或当前还未到可开票时间，请稍后再试。24小时后若依旧出现此错误，请咨询客服 400-180-0000',
		obtaine: '当前还未获取到可开票订单，请稍后再试',
		latestxy: '请使用时间为',
		latestxe: '，交易单号为',
		latestxs: '的最新小票进行开票申请',
		latestsy: '请使用时间为',
		latestse: '，账单号为',
		latestss: '的最新小票进行开票申请',
		current: '当前订单无法开票，请咨询客服 400-180-0000',
		cancell: '当前订单已作废，如有问题，请咨询客服 400-180-0000',
		amount: '当前订单可开票金额为0，不能开票，如有问题，请咨询客服 400-180-0000',
		paper: '该订单已通过人工开具纸质发票，请等待发票邮寄，如有问题，请咨询客服 400-180-0000',
		check: '该订单已通过人工开具电子发票，请查看开票时提供的邮箱或短信接收电子发票文件，如有问题，请咨询客服 400-180-0000',
	},
	AppPage: {
		Fapiaoinformation: '开票信息',
		FapiaoFAQ: '开票说明及常见问题',
		Fapiaotype: '开具类型',
		Valueaddec: '增值税电子普通发票',
		Valueaddbes: '数电票(增值税专用发票)',
		Valueaddbec: '数电票(普通发票)',
		Titletype: '抬头类型',
		chosetypei: '开票类型*',
		chosetype: '选择开票类型*',
		Institution: '企业',
		Institutionx: '企业及',
		Institutiony: '政府事业单位',
		Personal: '个人或政府事业单位',
		Fapiaotitle: '发票抬头',
		inputtitle: '输入购方名称*',
		inputtitlei: '购方名称*',
		SelectTitle: '选择抬头',
		RegisNumber: '纳税人识别号',
		inputnumber: '输入纳税人识别号*',
		inputnumberi: '纳税人识别号*',
		inputnumberx: '输入纳税人识别号',
		Showmoreinfo: '更多发票信息',
		Hidemoreinfo: '收起发票信息',
		CompanyAddress: '公司地址',
		inputaddress: '输入公司地址',
		inputaddressi: '公司地址',
		Bankname: '开户银行',
		inputbankname: '输入开户银行',
		inputbanknamei: '开户银行',
		Telephone: '电话号码',
		inputtelephone: '输入电话号码',
		inputtelephonei: '电话号码',
		Bankaccount: '银行账户',
		inputaccount: '输入银行账户',
		inputaccounti: '银行账户',
		Remarks: '发票备注',
		inputRemarks: '输入发票备注',
		inputRemarksi: '发票备注',
		information: '收票信息',
		chose: '请任选以下一种收票方式',
		Mobile: '手机号码',
		phonenumber: '输入个人手机号',
		phonenumberi: '个人手机号',
		Email: '电子邮箱',
		emailaddress: '输入个人邮箱',
		emailaddressi: '个人邮箱',
		sendmethod: '电子发票会发送到',
		Fapiaoamount: '开票金额',
		Details: '查看详情',
		Collapse: '收起详情',
		Ordernumber: '订单号',
		Transtime: '订单时间',
		Transamount: '订单金额',
		Transdetails: '商品明细',
		Item: '商品',
		Amount: '数量',
		Price: '价格',
		Issuing: '可开票',
		Payment: '支付明细',
		Submit: '确认提交',
		Link1: '为实现电子发票开具功能，并向您发送电子发票，我们需要您填写特定信息。我们收集此信息后将仅用于上述目的。请查阅我们的',
		Link2: '《隐私政策》',
		Link3: '以进一步了解我们的隐私实践。您勾选后将被视为同意我们根据',
		Link4: '对您上述信息的收集和处理。',
		yes: '是',
		no: '否',
	},
	ErrMsgAppPage: {
		title: '请填写购方名称',
		cortitle: '请填写正确的购方名称',
		regist: '请填写纳税人识别号',
		corregist: '请填写正确的纳税人识别号',
		mobile: '请填写正确的手机号',
		email: '请填写正确的邮箱',
		receive: '请至少填写一种收票信息 ',
		Unable: '无法开具购销方一致且不属于迪士尼主体的发票，请检查！',
		company: '请填写正确的公司地址 ',
		bankdeposit: '请填写正确的开户银行 ',
		phone: '请填写正确的电话号码 ',
		bankaccount: '请填写正确的银行账户 ',
		remark: '请填写正确的备注 ',
	},
	RePage: {
		ConIfm: '确认开票信息',
		Fapiaoamount: '开票金额',
		Fapiaotype: '发票类型',
		Valueaddec: '增值税电子普通发票',
		Valueaddbes: '数电票(增值税专用发票)',
		Valueaddbec: '数电票(普通发票)',
		Fapiaotitle: '购方名称',
		TaxRegisNumber: '纳税人识别号',
		Mobile: '手机号码',
		Email: '电子邮箱',
		Company: '公司地址',
		Bankdeposit: '开户银行',
		Phone: '电话号码',
		Bankaccount: '银行账户',
		Confirm: '确认提交',
		Return: '返回修改',
		Nothing: '无',
	},
	SubmitPage: {
		EFapiaopro: '开票申请处理中',
		EFapiaowait: '发票预计24小时内完成开具，成功后将发送至您填写的联系方式，请耐心等待商家处理',
		Faamount: '开票金额',
		Fatype: '发票类型',
		Apptime: '申请时间',
	},
	VeriPage: {
		ViewFapiao: '查看发票',
		inputfirst: '请输入最近一次填写的收票信息',
		inputfirstf: '请输入',
		inputfirsts: '最近一次填写的收票信息',
		Mobile: '手机号码',
		inputmobile: '请输入个人手机号后四位',
		inputmobilei: '个人手机号后四位',
		Email: '电子邮箱',
		inputemail: '请输入个人邮箱',
		inputemaili: '个人邮箱',
		Check: '校验',
	},
	ErrMsgVeriPage: {
		Mobile: '请输入发票申请时预留的手机号码后4位',
		Email: '请输入发票申请时预留的邮箱',
		MobileOrEmail: '请输入预留的手机号码或邮箱',
		Check: '您输入的预留信息未匹配，请确认输入内容是否正确或联系客服中心400-180-0000',
	},
	IsSucPage: {
		Efsuc: '发票开具成功',
		hassent: '开具发票已发送至您填写的联系方式',
		amount: '开票金额',
		type: '发票类型',
		time: '申请时间',
		View: '查看发票',
		Viewf: '查看',
		Viewt: '发票',
		Send: '发送至手机号或邮箱',
		AddCards: '加入微信/支付宝卡包',
		sendphone: '发到手机',
		sendphonef: '发到',
		sendphones: '手机',
		sendemail: '发到邮箱',
		sendemailf: '发到',
		sendemails: '邮箱',
		addcard: '加入卡包',
		addcardf: '加入',
		addcardt: '卡包',
		problem: '开票遇到问题？',
		contact: '联系客服',
		poptitleo: '发送发票至邮箱',
		poptitlez: '发送发票至手机',
		popcont: '剩余发送次数：',
		closeyes: '发送',
		closecancel: '取消',
		inputphone: '输入手机号',
		inputphonei: '手机号',
		inputemail: '输入邮箱',
		inputemaili: '邮箱',
		errmsge: '请输入发票申请时预留的手机号码后4位',
		errmsgf: '请输入发票申请时预留的电子邮箱',
		sending: '发送中...',
		sendsucem: '已将发票发送至您的邮箱：',
		sendsucph: '已将发票发送至您的手机：',
	},
	PopMsgSucPage: {
		Failed: '加入微信卡包/支付宝卡包失败',
		contact: '发票文件加载失败，请联系客服中心 400-180-0000',
		successfully: '加入微信卡包/支付宝卡包成功',
	},
	IsFailPage: {
		failed: '发票开具失败',
		contact: '您的电子发票开具失败，请联系客服400-180-0000查询具体原因。',
		amount: '开票金额',
		type: '发票类型',
		time: '申请时间',
	},
	ViewPage: {
		remarks: '*注：以下展示均为发票样张，不可代表真实发票。真实发票请以短信、邮箱或下方“下载电子版”获取的发票为准。',
		button: '下载电子版',
	},
	PopMsgViewPage: {
		download: '发票文件下载失败，请确认您的手机是否开启文件保存权限',
		load: '发票文件加载失败，请联系客服中心 400-180-0000',
		Downloaded: '发票文件已下载',
		success: '成功',
	},
	PopSendFp: {
		Fapiao: '发送发票',
		Mobile: '手机号码/ 电子邮箱',
		Remaining: '剩余可发送次数',
		Send: '发送',
	},
	PopSendErr: {
		mobile: '请填写正确的手机号',
		email: '请填写正确的邮箱',
		load: '发票文件加载失败，请联系客服中心 400-180-0000',
		success: '发送成功，请通过预留信息查看发票文件 ',
		time: '发票发送次数超限制，请联系客服中心协助 400-180-0000.',
	},
	ErrScenario: {
		Error: '系统异常',
		Try: '请关闭此页面再试一次',
		Closetitle: '比斯特苏州购物村上海迪士尼度假区',
		Closetitle1: '商店已正式闭店',
		Closevalue: '2024年10月30日17时起，本店已无法开具发票，',
		Closevalue1: '为您造成的不便我们深表歉意。',
	},
	FapiaoFAQ: {
		institle: '开票说明',
		insconfir: '*如需发票，请在付款成功30分钟后自助申请。请妥善保管收银小票，作为您获取发票的凭证。如果发生退换货，应以最新的小票为开票凭证。',
		insconsec: '*使用实体或电子礼品卡，优惠券等优惠支付的金额不开具发票。',
		insconthi: '*自助申请仅可开具电子普通发票，如需纸质普通发票，可咨询所消费商店/餐厅 或前往游客服务中心*或者拨打400-180-0000。如需开具增值税专用发票、电子发票开票异常或者购买超过24个月，请前往游客服务中心*或者拨打400-180-0000获得帮助。',
		insconfor: '*游客服务中心位于：主入口游客服务中心，散客服务处以及迪士尼小镇游客服务中心。',
		FAQtitle: '开票常见问题',
		qsfir: '问题：增值税电子普通发票和纸质增值税普通发票，法律效力一样吗？',
		asfir: '回答：增值税电子普通发票与纸质增值税普通发票具有同等法律效力，可作为财务记账或报销的合法凭证。',
		qssec: '问题：如何申请纸质增值税发票？',
		assec: '回答：若您有纸质增值税发票需求请联系客服。',
		qsthi: '问题：如果我不小心申请了增值税电子普通发票，但是想换成纸质普通发票或者纸质增值税专用发票，应该怎么办？',
		asthi: '回答：请前往游客服务中心或者拨打400-180-0000告知需要发票换开。换开发票时，系统会对第一张发票做红冲处理，红冲成功后第一张发票将失效。并会以订单当前的实付金额重新开具发票。',
		qsfor: '问题：我不小心填错了开票信息，导致开具了错误信息的发票，应该怎么办？',
		asfor: '回答：请前往游客服务中心或者拨打400-180-0000告知票面信息填错，需要重新开具发票。',
		asforfir: '若原发票为电子发票，您将收到一张红字发票, 原发票将失效。收到红字发票后，您可以通过小票，重新发起开票流程开具电子普通发票。或者告知客服需要修改的信息，由后台员工开具电子普通发票，并发送到您首次申请时预留的收票信息。请注意，若在重开过程中，发生了退换货，最终开票金额会以订单当前的实付金额开具发票。',
		asforsec: '若原发票为纸质普通发票，需归还原纸质普票发票。此票将失效。若您还是需要纸质普通发票，请告知客服需要修改的信息以及邮寄地址，由后台员工开具纸质普通发票后邮寄给您。请注意，若在重开过程中，发生了退换货，最终开票金额会以订单当前的实付金额开具发票。',
		asforthi: '若原发票为纸质专用发票，请归还原纸质专用发票。此票将失效。若您还是需要纸质专用发票，请告知客服需要修改的信息以及邮寄地址，由后台员工开具纸质专用发票后邮寄给您。请注意：若原发票已被“购买方”认证抵扣，则在重新开具新发票之前，需要您和“购买方”的配合开具红字发票；若在重开过程中，发生了退换货，最终开票金额会以订单当前的实付金额开具发票。',
		qsfif: '问题：为什么订单开出的发票金额与订单金额存在差异？',
		asfif: '回答：',
		asfiffir: '仅开具实际支付金额的发票，不含优惠券、折扣、礼品卡等未实际支付部分。',
		asfifsec: '如果您的订单发生过退换货，开票金额将会扣除已退货商品并加入新购/换购产品合并开票。',
		qssix: '问题：我购买完商品后得到一张小票，发生了部分退换货又拿到了一张退换货小票。应该怎么开电子发票呢？',
		assix: '回答：请使用最新的小票开票。如果您使用第一张小票开票，系统会提示您最新小票的订单号和交易时间，帮助您找到最新的小票并发起开票。',
		qssev: '问题：申请开具电子普通发票后，发生了退换货，已开具的发票将如何处理？',
		assev: '回答：若您只是部分退换货，原电子普通发票会通过系统自动冲红，并对未发生退货的商品自动重新开具电子普通发票，并发送至您此前申请发票填写的邮箱或手机号。若整单退货，原电子普通发票将被自动全额红冲后失效。',
		qseigh: '问题：开票申请后没有收到电子发票？',
		aseigh: '回答：',
		aseighfir: '重新扫码进入开票申请页，若开票状态显示开票成功，您可以通过页面获取电子发票，或者点击重新发送。',
		aseighsec: '重新扫码进入开票申请页，若开票状态显示‘发票开具失败’，请联系客服。',
		aseighthi: '如果您的收票方式信息填写错误，无法通过验证查看发票。请前往游客服务中心或者拨打400-180-0000修改收票方式。',
		qsnine: '问题：在哪里可以查询到发票的真伪？',
		asnine: '回答：',
		asninefir: '可以拨打12366纳税服务咨询电话；',
		asninesec: '可以登录‘国家税务总局全国增值税发票查验平台’（',
		asninethi: 'https://inv-veri.chinatax.gov.cn',
		asninefor: '）进行发票信息一致性查询；',
		asninefif: '可以携带发票原件到主管税务机关进行真伪鉴定。',
		qsten: '问题：为什么在 ‘国家税务总局全国增值税发票查验平台’ 无法查到发票？',
		asten: '回答：发票上传税局系统可能存在延迟，建议您在开具发票后1-2个工作日后再查询。',
	}

}
export default ZH_CN
